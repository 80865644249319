:root {
  --automator-header-height: calc(100vh - 68px - 41px);
}

.automator-height-container {
  height: var(--automator-header-height);
}

.automator-mock-env.automator-height-container {
  height: calc(100vh - 45px);
}

.sqd-designer-svelte {
  width: 100%;
}

.sqd-designer {
  width: 100%;
  overflow: hidden;
  color: #000;
}

.sqd-editor {
  padding: 10px;
}

.sqd-editor input:read-only {
  opacity: 0.4;
}

.sqd-toolbox {
  width: 200px;
}

.sqd-toolbox-filter,
.sqd-toolbox-item {
  width: 180px;
}

.sqd-smart-editor-toggle {
  display: none;
}

.sqd-theme-light .sqd-smart-editor {
  background: transparent;
  box-shadow: none;
}

.sqd-editor.sqd-step-editor,
.sqd-editor.sqd-step-editor > div,
.sqd-designer-svelte,
.sqd-designer {
  height: 100%;
}

.sqd-step-hidden > rect,
.sqd-step-hidden > text,
.sqd-step-hidden > image {
  opacity: 33%;
}

.region-canvas-wrapper .moveable-control-box {
  /* Reasonable z-index (default is 3000px) */
  z-index: 40;
}
